@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '../../assets/styles/global/variables';
@import '~bootstrap/scss/mixins';
@import '../../assets/styles/global/mixins';

.services-item {
  background-color: $gray-bg;
  border-radius: 24px;
  padding: 2.5rem 5.4375rem 2.5rem 2.5rem;
  display: block;
  text-decoration: none;
  height: 100%;
  position: relative;
  overflow: hidden;
  transition: all 0.4s ease-in-out;

  &.type3 {
    background-color: #cbdcee;
  }

  @include media-breakpoint-up(lg) {
    min-height: 8.125rem;
  }

  @include media-breakpoint-down(md) {
    padding: 1.875rem 3rem 1.875rem 1.5rem;
  }

  $this: &;

  &:hover {
    @include media-breakpoint-up(md) {
      background-color: $pink;

      #{$this}__title,
      #{$this}__text {
        color: $white;
      }

      #{$this}__arrow {
        transform: translateY(-50%) scale(1.375);
        color: $pink;

        &::after {
          background-color: $pink;
        }

        &::before {
          right: 10px;
        }
      }

      #{$this}__overlay {
        opacity: 1;
      }
    }
  }

  &__overlay {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  }

  &__title {
    color: #333;
    margin-bottom: 0.5rem;
    position: relative;
    z-index: 2;
    max-width: 26.875rem;

    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }

  &__text {
    color: #8d97a3;
    margin: 0;
    position: relative;
    z-index: 2;
    max-width: 26.875rem;
  }

  &__arrow {
    width: 2rem;
    height: 2rem;
    background-color: $white;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    z-index: 2;
    right: 2.5rem;
    top: 50%;
    transform: translateY(-50%);
    color: $black;
    transition: transform 0.3s ease;

    @include media-breakpoint-down(md) {
      right: 1.125rem;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      color: inherit;
      width: 15px;
      height: 1px;
      background-color: $black;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: background-color 0.3s ease;
    }

    &::before {
      content: '';
      position: absolute;
      display: block;
      color: inherit;
      width: 10px;
      height: 10px;
      border-top: solid 1px;
      border-left: solid 1px;
      top: 50%;
      right: 8px;
      transform: translateY(-50%) rotateZ(135deg);
      transition: color 0.3s ease;
    }
  }
}

@include color-mode(dark) {
  .services__item {
    border: 1px solid #4d4d4d;
  }
}

.services-items-wrapper {
  > .container {
    @include media-breakpoint-down(xxl) {
      max-width: 1410px;
    }
  }
}

.services-item__spacer {
  margin-bottom: 0.75rem;

  @include media-breakpoint-down(md) {
    margin-bottom: 0.75rem;
  }
}
